import { Recipe } from "../../../model/Recipe";
import { List, ListItem, ListItemText, Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/ApiService";
import { Ingredient } from "../../../model/Ingredient";

export default function RecipeDetails() {

    const { state } = useLocation();
    const recipe = state as Recipe;

    const [ingredients, setIngredients] = useState([] as Ingredient[]);

    useEffect(() => {
        ApiService.getIngredients().then(response => {
            setIngredients(response.data);
        })
    }, [])

    if (!recipe) return (<></>);

    return (
        <Stack spacing={2}>
            <Paper>{recipe.name}</Paper>
            <Paper>{recipe.description}</Paper>
            <Paper>
                <List>
                    Ingredients
                    {recipe.ingredients.map((ingredient, index) => {
                        const ingredientName = ingredients.find(ingr => ingr.id == ingredient.ingredientId)?.name;
                        return (
                            <ListItem key={'ingredient-' + index}>
                                <ListItemText
                                    // primary={step}
                                    secondary={`- ${ingredientName ?? '??'}`} />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
            <Paper>
                <List>
                    Steps
                    {recipe.steps.map((step, index) => {
                        return (
                            <ListItem key={'step-' + index}>
                                <ListItemText
                                    // primary={step}
                                    secondary={`${index + 1}. ${step}`} />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
        </Stack>
    )
}