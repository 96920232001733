import { AppBar, Toolbar, Typography } from "@mui/material"
import React from "react"
import { useAuth } from "react-oidc-context";

export function Navigation() {

  const auth = useAuth();

  return (
    <AppBar
      position="absolute"
      color="primary"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
        </Typography>
        Plan your meals {auth.user?.profile.preferred_username}
      </Toolbar>
    </AppBar>
  )
}
