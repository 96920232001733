import { Badge, Paper, Typography } from '@mui/material';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { useEffect } from 'react';
import {Day as MealsOfDay} from "../../../model/Day"
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import {formatToDateOnly} from "../../../Common";

interface PlannerParams {
    date: Date | null,
    onDateChange: (newDate: Date | null) => void
}

export default function Planner({ date, onDateChange } : PlannerParams) {
    const [highlightedDays, setHighlightedDays] = React.useState([] as number[]);
    const [month, setMonth] = React.useState(date ?? new Date());

    const auth = useAuth();

    let config = {
      headers: {
        Authorization: 'Bearer ' + auth.user?.access_token,
      }
    }

    useEffect(() => {
        const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
        const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        axios.get<MealsOfDay[]>(`https://meal-calculator.siof.pl/api/days/meals?fromDate=${formatToDateOnly(monthStart)}&toDate=${formatToDateOnly(monthEnd)}`, config)
        .then(response => {
            extractHighlightedDays(response.data);
        })
    }, [date, month]);

    function extractHighlightedDays(mealsOfDay: MealsOfDay[]) {
        let days = mealsOfDay.map(day => {
            return new Date(day.date).getDate();
        });

        days = days.filter((value, index) => days.indexOf(value) === index);
        if (days) {
            setHighlightedDays(days);
        }
    } 

    function handleMonthChange(month: Date): void {
        setMonth(month);
    }

    const selectedDays = (datePickerProps: PickersDayProps<Date> & { highlightedDays?: number[] }) => {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = datePickerProps;

        const isSelected =
            !datePickerProps.outsideCurrentMonth &&
            highlightedDays.indexOf(datePickerProps.day.getDate()) >= 0;

        return (
            <Badge
                key={datePickerProps.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? '🌚' : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    return <>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo='day'
                    value={date}
                    onMonthChange={handleMonthChange}
                    onChange={(newValue) => {
                        onDateChange(newValue);
                    }}
                    slots={{
                        day: selectedDays
                    }}
                    slotProps={{
                        day: {
                            highlightedDays,
                        } as any
                    }}
                />
            </LocalizationProvider>
        </Paper>
    </>
}