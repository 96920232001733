import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigation } from '../components/Navigation';
import NotFoundPage from './NotFoundPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppUrlListener from '../AppUrlListener';
import RecipeDetails from './meal_planner/recipes/RecipeDetails';
import { Main } from './meal_planner/Main';


const defaultTheme = createTheme({palette: {
  primary: { main: '#f5c98f'},
  secondary: { main: '#f0e08e'},
  error: { main: '#f5968f'},
  warning: { main: '#f5968f'},
  success: { main: '#8fbbf5'},
}});

export default function Layout() {


  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<Main />} />
          <Route path="/recipe/*" element={<RecipeDetails />} />
        </Routes>
        <AppUrlListener></AppUrlListener>

      </BrowserRouter>
      
    </ThemeProvider>
  );
}
