import * as React from 'react';
import { Stepper as MuiStepper, Step, StepLabel } from '@mui/material';

export default function Stepper(props: { activeStep: number, steps: string[] }) {

    return (
        <MuiStepper activeStep={props.activeStep}
            sx={{ pt: 3, pb: 5 }}
        >
            {props.steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </MuiStepper>
    )
}