import React, {useEffect} from 'react';
import {App, URLOpenListenerEvent} from '@capacitor/app';


const AppUrlListener: React.FC<any> = () => {

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('siof.pl').pop();
      if (slug) {
        document.location.href = slug; // force refresh in capacitor
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};

export default AppUrlListener;