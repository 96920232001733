import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import React from "react";

export class GlycemicLoad {
    public static LOW = new GlycemicLoad('Low glycemic load', 'lightgreen', <></>);
    public static MEDIUM = new GlycemicLoad('Medium glycemic load', 'warning.main', <WarningIcon sx={{ ml: 1 }}/>);
    public static HIGH = new GlycemicLoad('High glycemic load', 'error.main', <ReportIcon/>);

    private message: string;
    private colour: string;
    private icon: React.ReactNode;

    private constructor(message: string, colour: string, icon: React.ReactNode) {
        this.message = message;
        this.colour = colour;
        this.icon = icon;    
    }

    public getMessage() {
        return this.message;
    }

    public getColour() {
        return this.colour;
    }

    public getIcon(): React.ReactNode {
        return this.icon;
    }
}