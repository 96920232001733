import { Grid, Typography } from "@mui/material"

export default function Value(props: {
    label: string, value: string, indicator?: React.ReactNode
}) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
                <Typography align="right" variant="h6" component="div">
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography align="left" variant="h6" component="div">
                    {props.value}
                    {props.indicator ? props.indicator : <></>}
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
        </Grid>
    )
}