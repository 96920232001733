import {Ingredient} from "./Ingredient";

export interface MealIngredient {
    ingredientId: number;
    weight: number;
}

export function mapFromIngredient(ingredient: Ingredient): MealIngredient {
    return {
        ingredientId: ingredient.id,
        weight: ingredient.weight
    } as MealIngredient;
}