import React from "react";
import { Ingredient } from "../../model/Ingredient";
import { Autocomplete, Grid, TextField, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


export default function IngredientLine(props: {
    ingredient: Ingredient, index: number,
    availableIngredientsKeys: string[],
    onChangeSelectedIngredients: (index: number, newValue: string) => void,
    onChangeSelectedIngredientsWeight: (index: number, newValue: number) => void,
    onRemoveIngredient: (index: number) => void
}) {

    return (
        <React.Fragment>
            <Grid item xs={7} sm={6}>
                <Autocomplete
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue) {
                            props.onChangeSelectedIngredients(props.index, newValue);
                        }
                    }
                    }
                    disablePortal
                    data-testid={"ingredient-combo-box-" + props.index}
                    value={props.ingredient.name}
                    options={props.availableIngredientsKeys}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Ingredient" />}
                />
            </Grid>
            <Grid item xs={4} sm={4}>
                <TextField
                    required
                    id="weight"
                    name="weight"
                    label="Weight [g]"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        props.onChangeSelectedIngredientsWeight(props.index, event.target.value)
                    }}
                    value={props.ingredient.weight}
                />
            </Grid>
            <Grid item xs={1} sm={1}>
{/*             <Tooltip title={'Remove this ingredient from your meal'}> */}
            <DeleteIcon onClick={() => props.onRemoveIngredient(props.index)} sx={{ mt: 1.5 }}/>
{/*             </Tooltip> */}
            </Grid>
        </React.Fragment>
    )
}
