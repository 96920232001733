import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function StepButtons(props: {
    activeStep: number, steps: string[],
    handleNewMeal: () => void;
    handleBack: () => void;
    handleNext: () => void;
    saveMeals: () => void;
}) {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.activeStep !== 0 && (
                <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                </Button>
            )}
            {(props.activeStep != props.steps.length - 1) ?
                <>
                    <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={props.handleNewMeal}>New meal</Button>
                    <Button
                        variant="contained"
                        onClick={props.handleNext}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Next
                    </Button></>
                : <Button
                    variant="contained" sx={{ mt: 3, ml: 1 }}
                    onClick={props.saveMeals}>
                    Save
                </Button>
            }
        </Box>
    );
}