import axios from "axios";
import { Day as MealsOfDay } from "../model/Day"
import { formatToDateOnly } from "../Common";
import { AuthContextProps } from "react-oidc-context";
import { Ingredient } from "../model/Ingredient";

export class ApiService {

    private static baseUrl = 'https://meal-calculator.siof.pl/api/';

    static async getIngredients() {
        return axios.get<Ingredient[]>(this.baseUrl + "ingredients");
        
    }

    static async getMealsOfDays(date: Date, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<MealsOfDay[]>(this.baseUrl + `days/meals?fromDate=${formatToDateOnly(date)}&toDate=${formatToDateOnly(date)}`, config);
    }

    static async getRecipes(auth: AuthContextProps, page: number) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<any[]>(this.baseUrl + `recipes?page=${page}&results=5`, config);
    }
}