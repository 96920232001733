import './App.css';
import Layout from './pages/Layout';
import {useAuth} from 'react-oidc-context';

function App() {

  const auth = useAuth();

  if (auth.isLoading) {
    return <div>Loading 2...</div>;
  }

  if (!auth.isAuthenticated) {
    // console.log('not authenticated')
    auth.signinRedirect().catch((error) => {
      console.log(error);
    })
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Layout></Layout>
    </div>
  );
}

export default App;
