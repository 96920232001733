import ListItemButton from "@mui/material/ListItemButton";
import { Recipe } from "../../../model/Recipe";
import { ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function RecipeLine(props: { recipe: Recipe }) {

    const navigate = useNavigate();

    function handleClick(recipeId: number): any {
        navigate('/recipe/' + recipeId, { state: props.recipe });
    }

    return (
        <ListItemButton key={props.recipe.name} component="a">
            <ListItemText primary={props.recipe.name} onClick={()=> handleClick(props.recipe.id)}/>
        </ListItemButton>
    )
}