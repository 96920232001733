import { ListItemButton, ListItemText, Paper } from "@mui/material";
import { ApiService } from "../../../services/ApiService";
import { useAuth } from "react-oidc-context";
import React, { useEffect } from "react";
import { Recipe } from "../../../model/Recipe";
import RecipeLine from "./RecipeLine";

export default function RecipeBrowser(props: {applyRecipe: (recipe: Recipe) => void}) {

    const auth = useAuth();
    const [recipes, setRecipes] = React.useState([] as Recipe[]);

    useEffect(() => {
        ApiService.getRecipes(auth, 0)
            .then((response) => {
                setRecipes(response.data)
            })
    }, [])

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {recipes.map(recipe => {
                return (
                    <RecipeLine recipe={recipe} key={recipe.name} />
                )
            })}
        </Paper>
    );
}