import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MealDetails from './meal_planner/MealDetails';
import DailySummary from './meal_planner/DailySummary';
import { Meal } from '../model/Meal';
import { ReactNode, useEffect } from 'react';
import axios from 'axios';
import { Day as MealsOfDay } from "../model/Day"
import { useAuth } from 'react-oidc-context';
import { ApiService } from '../services/ApiService';
import { Recipe } from '../model/Recipe';
import StepButtons from './meal_planner/meals_builder/StepButtons';
import Stepper from './meal_planner/meals_builder/Stepper';

let lastStep = 'Save';
let initialSteps = [
  'Meal 1',
  lastStep
];

interface CalculationStepperProps {
  date: Date,
  recipeToBeApplied?: Recipe | undefined,
  setRecipeToBeApplied?: (recipe: Recipe) => void
}

export default function CalculationStepper({ date }: CalculationStepperProps) {
  const [steps, setSteps] = React.useState(initialSteps);
  const [activeStep, setActiveStep] = React.useState(0);
  const [meals, setMeals] = React.useState<Map<number, Meal>>();
  const auth = useAuth();

  let config = {
    headers: {
      Authorization: 'Bearer ' + auth.user?.access_token,
    }
  }

  useEffect(() => {
    ApiService.getMealsOfDays(date, auth)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.length > 0 && response.data[0].meals) {
          resetSteps(response.data[0].meals.map(meal => meal.name));
          setMeals(new Map<number, Meal>(response.data[0].meals.map(meal => {
            if (!meal.tmpId && meal.id) {
              meal.tmpId = meal.id;
            }
            return [meal.order, meal];
          })));
          setActiveStep(0);
        }
        setActiveStep(0);
      })
      .catch(reason => {
        console.log('getMealsOfDay ERROR', reason);
      });
  }, [date]);

  function resetSteps(newSteps: string[] | null) {
    if (!newSteps) {
      newSteps = initialSteps;
    } else {
      newSteps.push(lastStep);
    }
    setSteps(Array.of(...newSteps));
  }

  function updateSelectedMeal(meal: Meal) {
    // console.log('meal update:', meal.tmpId, meal.ingredients[0], meal.ingredients[1])
    let newMeals = meals;
    newMeals?.set(meal.tmpId, meal);
    setMeals(new Map<number, Meal>(meals));
  }

  function getStepContent(step: number) {
    switch (step) {
      case steps.length - 1:
        return <DailySummary meals={[]} />;
      default:
        return <MealDetails
          meal={(meals?.get(step)) ? meals?.get(step) as Meal : {
            tmpId: step,
            name: steps[step]
          } as Meal}
          date={date}
          onMealChange={updateSelectedMeal}
        />;
    }
  }

  function handleNewMeal(): void {
    steps.splice(steps.length - 1, 0, "Meal " + (activeStep + 2));
    setSteps(Array.of(...steps));
    setActiveStep(activeStep + 1);
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const saveMeals = () => {
    const body = {
      'date': date.toISOString().split('T')[0],
      'meals': Array.from(meals?.values() ?? [])
    };

    axios.post<MealsOfDay>("https://meal-calculator.siof.pl/api/days/meals", body, config).then((response) => {
      // todo?
    });
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <br />
        <Stepper activeStep={activeStep} steps={steps}/>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
            </Typography>
            <Typography variant="subtitle1">
              Saved.
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getStepContent(activeStep)}
            <StepButtons activeStep={activeStep} steps={steps} handleNewMeal={handleNewMeal} handleBack={handleBack} handleNext={handleNext} saveMeals={saveMeals}/>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
}
